import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout.js';
import Head from '../components/head';
import Bumbalogo from '../images/700x250.gif'
//* BEGIN import images
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../images/', false, /.jpg$/));
//* END import images
function Portfolio() {
  return (
    <Layout>
    <Head title="Nuestros libros" />
      <div className="portfolio-gallery">
        <Link to="/UniversoExpand/"><img className="portfolioImages" src={images['UniversoCover400.jpg']} alt=""/></Link>
        <a href="/LlanoExpand/"><img className="portfolioImages" src={images['LlanoCover400.jpg']} alt=""/></a>
        <a href="/PastelesExpand/"><img className="portfolioImages" src={images['DeliciososCover400.jpg']}  alt=""/></a>
        <a href="/ABCExpand/" title="ABC del Bebé"><img className="portfolioImages" src={images['ABCCover400.jpg']} alt=""/></a>
        <a href="/HoraExpand/" title="¡Hora de bañarse!"><img className="portfolioImages" src={images['BaniarseCover400.jpg']} alt=""/></a>
        <a href="/CiempiesExpand/" title="Andrés Ciempiés"><img className="portfolioImages" src={images['CiempiesCover400.jpg']}  alt=""/></a>
        <a href="/ComidaExpand/" title="¡La comida está servida!"><img className="portfolioImages" src={images['ComidaCover400.jpg']} alt=""/></a>
        <a href="/FamiliaExpand/" title="La familia Buen Bigote"><img className="portfolioImages" src={images['FamiliaCover400.jpg']} alt=""/></a>
        <a href="/NanaExpand/" title="Nana para un Nenedino"><img className="portfolioImages" src={images['NanaCover400.jpg']} alt=""/></a>
      </div>
    </Layout>
  );
}

export default Portfolio;
